// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-canopies-js": () => import("./../../../src/pages/canopies.js" /* webpackChunkName: "component---src-pages-canopies-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-ev-charging-js": () => import("./../../../src/pages/ev-charging.js" /* webpackChunkName: "component---src-pages-ev-charging-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-rooftop-js": () => import("./../../../src/pages/rooftop.js" /* webpackChunkName: "component---src-pages-rooftop-js" */),
  "component---src-pages-thanks-js": () => import("./../../../src/pages/thanks.js" /* webpackChunkName: "component---src-pages-thanks-js" */),
  "component---src-pages-utility-scale-js": () => import("./../../../src/pages/utility-scale.js" /* webpackChunkName: "component---src-pages-utility-scale-js" */),
  "component---src-templates-article-index-js": () => import("./../../../src/templates/article-index.js" /* webpackChunkName: "component---src-templates-article-index-js" */),
  "component---src-templates-article-page-js": () => import("./../../../src/templates/article-page.js" /* webpackChunkName: "component---src-templates-article-page-js" */)
}

